<template>
    <div>
        <div class="icon-tit">
            <van-icon name="arrow-left" @click="go()" />
        </div>
         <h3 class="title">{{ title }}</h3>
         <div class="content-p tac" v-if="isType === 0">
             您的资料已提交，请等待审核
         </div>
         <div class="content-p tac" v-if="isType === 1">

             您申请已成功
         </div>
         <div class="content-p"  v-if="isType === 2">
             <p class="tac">您申请已被驳回</p> 
            <p class="bohui-p">
                {{remark}}
            </p>
         </div>
         <div class="bom-btn">
            <van-button v-if="isType===0" type="info" @click="viewInfo(0)">查看申请进度</van-button>
            <van-button v-if="isType===1" type="info" @click="bidinfo(1)">竞价列表</van-button>
            <van-button v-if="isType===2" type="info" @click="anew(2)">重新申请</van-button>
         </div>
    </div>
</template>

<script>
import { micro_post } from "../../Http/api";
    export default {
          metaInfo(){
            return {
                 title: this.$route.query.shopId == '1078' ? '移动端拆船件' :    window.localStorage.getItem('getShopName') 
            }
        },
        data() {
            return {
                title:"",
                type:"",
                isType:0,
                remark:""
            }
        },
        created () {
            this.type = this.$route.query.type;
            this.shopId = this.$route.query.shopId;
             switch (this.type) {
                case "1":
                    this.title = "大宗竞价资格申请";
                break;
                case "2":
                    this.title = "大宗回用件竞价";
                break;
                case "3":
                    this.title = "回用件竞价资格申请";
                break;
                default:
                break;
            }
        },
        methods: {
            go(){
                this.$router.push({path:'/QualificationApplication',query:{shopId:this.$route.query.shopId,type:this.$route.query.type}})
            },
            anew(){
                this.$router.push({path:'/QualificationApplication',query:{shopId:this.$route.query.shopId,type:this.$route.query.type}})
            },
            bidinfo(){
                this.$router.push({path:'/BiddingList',query:{shopId:this.$route.query.shopId,type:this.$route.query.type}})
            },
            viewInfo() {
                let company = {
                    type:this.type,
                    shopId:this.shopId,
                }
                const data = {
                    url: "/index.php/BidManagement/find_company",
                    params: company,
                    success: (data) => {
                        if(data.code == 0){
                            // console.log(data)
                            this.isType = data.data.status
                            // if(data.data.status == 0){
                            //     this.$toast.fail("待审核中。。。")
                            // }else if(data.data.status == 1){
                            //     //成功
                               
                            // }else if(data.data.status == 2){
                            //     //驳回
                            //     this.remark = data.data.remark
                            // }
                        }

                    }
                };
                micro_post(data,this);
            }
        },
    }
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  font-size: 28px;
}
.content-p{
    
    font-size: 26px;
    margin: 100px 0;
    padding: 20px;
}
.bom-btn{
    text-align: center;
    .van-button{
        width: 80%;
        border-radius: 8px;
    }
}
.icon-tit{
    width: 100%;
    height: 50px;
    background-color: cadetblue;
    position: relative;
    .van-icon{
        font-size: 26px;
        position: absolute;
        top: 10px;
        left: 10px;
    }
}
.bohui-p{
    text-indent:35px
}
.tac{
 text-align: center;
}
</style>